import React, { useState } from "react";
import Modal from "react-modal";
import DeleteFolderModal from "./DeleteFolderModal";
import NewFolderModal from "./NewFolderModal";
import {
  FaFolder,
  FaFolderOpen,
  FaTrashAlt,
  FaPlus,
  FaCheck,
  FaTimes,
  FaChevronDown,
  FaChevronRight,
} from "react-icons/fa";

const FolderTreeModal = ({
  isOpenModal,
  closeModal,
  folders,
  setSelectedFolder,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // minWidth: "60vw",
      maxHeight: "66vh",
      maxWidth: "86vw",
      padding: "10px",
      borderRadius: "10px",
      backgroundColor: "#f3f4f6",
      // position: "relative",
      // overflowY: "auto",
    },
  };

  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);
  const [folderIdToDelete, setFolderIdToDelete] = useState(null);
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [parentFolderId, setParentFolderId] = useState(null);

  // Store expanded state for folders
  const [expandedFolders, setExpandedFolders] = useState({});

  // Function to handle opening the delete modal
  const openDeleteModal = (folderId) => {
    setFolderIdToDelete(folderId);
    setIsDeleteFolderModalOpen(true);
  };

  // Function to handle opening the new folder modal
  const openNewFolderModal = (parentId) => {
    setParentFolderId(parentId);
    setIsNewFolderModalOpen(true);
  };

  // Function to cancel folder selection
  const cancelSelection = () => {
    setSelectedFolderId(null);
    setSelectedFolder("");
  };

  // Function to toggle expansion state for a folder
  const toggleExpansion = (folderId) => {
    setExpandedFolders((prevExpandedFolders) => ({
      ...prevExpandedFolders,
      [folderId]: !prevExpandedFolders[folderId],
    }));
  };

  // Recursive Folder Component to handle subfolders with expand/collapse functionality
  const FolderTree = ({ folder }) => {
    const isExpanded = expandedFolders[folder.id] || false;
    const isSelected = selectedFolderId === folder.id;

    return (
      <div className="ml-4 mt-2 flex flex-col items-start raleway-regular">
        <div className="flex items-center">
          <button onClick={() => toggleExpansion(folder.id)} className="mr-2">
            {/* Show arrow icon based on expanded/collapsed state */}
            {folder.subfolders && folder.subfolders.length > 0 ? (
              isExpanded ? (
                <FaChevronDown />
              ) : (
                <FaChevronRight />
              )
            ) : (
              <span className="w-4" />
            )}
          </button>

          <label
            className={`flex items-center cursor-pointer font-bold space-x-2 ${
              isSelected ? "text-green-600 font-semibold" : "text-gray-800"
            }`}
          >
            <input
              type="radio"
              name="folder"
              className="cursor-pointer"
              checked={isSelected}
              onChange={() => {
                setSelectedFolderId(folder.id);
              }}
            />
            {/* Icon for folder */}
            <span className="text-xl">
              {isSelected ? <FaFolderOpen /> : <FaFolder />}
            </span>
            <span>{folder.name}</span>
          </label>

          {/* Delete folder button */}
          <button
            onClick={() => openDeleteModal(folder.id)}
            className="ml-2 text-red-500 hover:text-red-600 transition"
          >
            <FaTrashAlt />
          </button>

          {/* Add subfolder button */}
          <button
            onClick={() => openNewFolderModal(folder.id)}
            className="ml-2 text-blue-500 hover:text-blue-600 transition"
          >
            <FaPlus />
          </button>
        </div>

        {/* Subfolders (collapsible) */}
        {isExpanded && folder.subfolders && folder.subfolders.length > 0 && (
          <div className="ml-4">
            {folder.subfolders.map((subfolder) => (
              <FolderTree key={subfolder.id} folder={subfolder} />
            ))}
          </div>
        )}
      </div>
    );
  };

  // Recursive function to find the folder by ID
  const findFolderById = (folders, id) => {
    for (let folder of folders) {
      if (folder.id === id) {
        return folder;
      } else if (folder.subfolders && folder.subfolders.length > 0) {
        const found = findFolderById(folder.subfolders, id);
        if (found) return found;
      }
    }
    return null;
  };

  const handleSelect = () => {
    const selected = findFolderById(folders, selectedFolderId);
    if (selected) {
      setSelectedFolder(selected);
      closeModal();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        onRequestClose={closeModal}
        contentLabel="Folder Tree Modal"
        // className="bg-white rounded-lg p-6 w-full max-w-2xl mx-auto my-20 outline-none shadow-lg"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
        ariaHideApp={false}
        style={customStyles}
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-700">
          Select a Folder
        </h2>
        <div className="folder-tree max-h-80 overflow-y-auto border-t border-b border-gray-300 py-4">
          {folders.length > 0 ? (
            folders.map((folder) => (
              <FolderTree key={folder.id} folder={folder} />
            ))
          ) : (
            <p className="text-gray-500">No folders available.</p>
          )}
        </div>
        <div className="flex mt-6 justify-center flex-wrap gap-1">
          <div className="space-x-2">
            {/* Cancel selection button */}
            <button
              onClick={cancelSelection}
              className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 transition"
            >
              <FaTimes className="inline mr-1" /> Cancel Selection
            </button>
            {/* Select folder button */}
            <button
              onClick={handleSelect}
              disabled={!selectedFolderId}
              className={`bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition ${
                !selectedFolderId ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <FaCheck className="inline mr-1" /> Select Folder
            </button>
          </div>
          <div className="space-x-2">
            {/* Close modal button */}
            <button
              onClick={closeModal}
              className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition"
            >
              <FaTimes className="inline mr-1" /> Close
            </button>
            {/* Add new root folder button */}
            <button
              onClick={() => openNewFolderModal(null)}
              className="bg-green-500 text-white py-2 px-4 rounded ml-2 hover:bg-green-600 transition"
            >
              <FaPlus className="inline mr-1" /> Add New Folder
            </button>
          </div>
        </div>
      </Modal>

      {/* Delete Folder Modal */}
      {folderIdToDelete && (
        <DeleteFolderModal
          isDeleteFolderModalOpen={isDeleteFolderModalOpen}
          closeDeleteFolderModal={() => setIsDeleteFolderModalOpen(false)}
          folderId={folderIdToDelete}
          setFolderId={setFolderIdToDelete}
        />
      )}

      {/* New Folder Modal */}
      <NewFolderModal
        isNewFolderModalOpen={isNewFolderModalOpen}
        closeNewFolderModal={() => setIsNewFolderModalOpen(false)}
        parentFolderId={parentFolderId}
      />
    </>
  );
};

export default FolderTreeModal;
