import React from "react";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import { TiTick } from "react-icons/ti";

Modal.setAppElement("#root"); // Important for accessibility

const SubscriptionSuccessModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="relative p-8 bg-white rounded-lg w-80 text-center shadow-lg"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <button
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl"
        onClick={onRequestClose}
      >
        <FaTimes />
      </button>
      <div className="flex flex-col items-center">
        <div className="w-12 h-12 mb-4 flex items-center justify-center bg-green-500 rounded-full">
          <TiTick color="white" size={40} />
        </div>
        <h2 className="text-xl font-semibold text-gray-700">
          Subscription successful!
        </h2>
      </div>
    </Modal>
  );
};

export default SubscriptionSuccessModal;
