import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import toast from "react-hot-toast";
import PreviewModal from "./PreviewModal";

const BulkDownloadModal = ({
  isBulkDownloadModalOpen,
  closeBulkDownloadModal,
  downloadBulkModalContent,
}) => {
  const [loadingState, setLoadingState] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "60vw",
      minHeight: "70vh",
      maxHeight: "90vh",
      maxWidth: "90vw",
      padding: "20px",
      borderRadius: "10px",
      backgroundColor: "#F6F5F5",
      overflow: "none",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const handleFileSelection = (fileId) => {
    if (selectedFiles.includes(fileId)) {
      setSelectedFiles(selectedFiles.filter((id) => id !== fileId));
    } else {
      setSelectedFiles([...selectedFiles, fileId]);
    }
  };

  // Handle "Select All" functionality
  const handleSelectAll = () => {
    if (selectedFiles.length === downloadBulkModalContent.length) {
      setSelectedFiles([]);
    } else {
      setSelectedFiles(downloadBulkModalContent.map((file) => file.id));
    }
  };

  const downloadFiles = async (fileFormat, is_only_table) => {
    if (selectedFiles.length === 0) {
      toast("Please select at least one file to download.", {
        duration: 2000,
      });
      return;
    }

    try {
      setLoadingState({ format: fileFormat, isOnlyTable: is_only_table });

      const ids = selectedFiles.join("&ids=");
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      const url = `${process.env.REACT_APP_BACKEND_URL}/uploads/generate-file/?fileFormat=${fileFormat}&ids=${ids}&isOnlyTable=${is_only_table}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      // Create a link and trigger download
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `bulk_download.${fileFormat}`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      setLoadingState({});
    } catch (error) {
      console.error("Failed to download the files", error);
      setLoadingState({});
    }
  };

  // Sync selectedFiles with downloadBulkModalContent when content changes
  useEffect(() => {
    setSelectedFiles(downloadBulkModalContent.map((file) => file.id));
  }, [downloadBulkModalContent]);

  const handleRowClick = (file) => {
    const fileExtension = file?.file?.split(".").pop().toLowerCase();
    const fileType = ["pdf", "jpg", "jpeg", "png"].includes(fileExtension)
      ? fileExtension
      : "unknown";
    setSelectedFile({
      fileLink: process.env.REACT_APP_BACKEND_URL + file?.file,
      format: fileType,
      name: file?.file?.split("/").pop(),
      id: file?.id,
      information: file?.information,
      status: file?.status,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
  };

  const formatName = (name) => {
    if (!name) return "";

    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const isBankStatement =
    downloadBulkModalContent[0]?.file_type === "BANK_STATEMENT";

  return (
    <div>
      <Modal
        isOpen={isBulkDownloadModalOpen}
        onRequestClose={closeBulkDownloadModal}
        contentLabel="Bulk Download Modal"
        style={customStyles}
      >
        <h2 className="raleway-regular text-center mb-4">
          Bulk Download Items
        </h2>

        {downloadBulkModalContent && downloadBulkModalContent.length > 0 ? (
          <div className="p-4 raleway-regular h-full flex flex-col">
            <div className="mb-4 sticky top-0 bg-white p-2 z-10 rounded-md">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={
                    selectedFiles.length === downloadBulkModalContent.length
                  }
                  onChange={handleSelectAll}
                />
                <span className="cursor-pointer">Select All</span>
              </label>
            </div>
            <div className="flex-grow overflow-y-auto mb-20 max-h-[60vh]">
              {downloadBulkModalContent.map((file, index) => (
                <div
                  key={index}
                  className="mb-4 p-2 border rounded shadow-sm flex items-center justify-between"
                >
                  <div className="flex items-center space-x-4">
                    {/* Checkbox for individual file selection */}
                    <input
                      type="checkbox"
                      checked={selectedFiles.includes(file.id)}
                      onChange={() => handleFileSelection(file.id)}
                    />
                    <p
                      className="text-sm font-semibold cursor-pointer"
                      onClick={() => handleRowClick(file)}
                    >
                      Name:{" "}
                      <span className="font-normal">
                        {file?.file_name
                          ? file?.file_name
                          : file.file.split("/").pop()}
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-between gap-8">
                    <p className="text-sm font-semibold">
                      Type:{" "}
                      <span className="font-normal">
                        {formatName(file.file_type)}
                      </span>
                    </p>
                    <p className="text-sm font-semibold">
                      Owner:{" "}
                      <span className="font-normal">
                        {file?.owner_details?.first_name +
                          " " +
                          file?.owner_details?.last_name}
                      </span>
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="fixed bottom-12 left-0 right-0 flex justify-center space-x-4">
              {isBankStatement && (
                <div className="flex flex-col bg-slate-50 p-2 rounded-md shadow-sm">
                  <span className="font-semibold text-blue-600 mb-1">
                    Download Only Table
                  </span>
                  <div className="flex gap-5">
                    <button
                      onClick={() => downloadFiles("csv", true)}
                      disabled={
                        loadingState.format === "csv" &&
                        loadingState.isOnlyTable === true
                      }
                      className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                    >
                      {loadingState.format === "csv" &&
                      loadingState.isOnlyTable === true
                        ? "Downloading..."
                        : "Download CSV"}
                    </button>
                    <button
                      onClick={() => downloadFiles("xlsx", true)}
                      disabled={
                        loadingState.format === "xlsx" &&
                        loadingState.isOnlyTable === true
                      }
                      className="bg-white text-green-500 px-4 py-2 border border-green-400 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 hover:bg-green-400 hover:text-white transition duration-300 ease-in-out"
                    >
                      {loadingState.format === "xlsx" &&
                      loadingState.isOnlyTable === true
                        ? "Downloading..."
                        : "Download XLSX"}
                    </button>
                  </div>
                </div>
              )}
              <div className="flex flex-col bg-slate-50 p-2 rounded-md shadow-sm">
                {isBankStatement && (
                  <span className="font-semibold text-blue-600 mb-1">
                    Download All Data
                  </span>
                )}
                <div className="flex gap-5">
                  <button
                    onClick={() => downloadFiles("csv", false)}
                    disabled={
                      loadingState.format === "csv" &&
                      loadingState.isOnlyTable === false
                    }
                    className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                  >
                    {loadingState.format === "csv" &&
                    loadingState.isOnlyTable === false
                      ? "Downloading..."
                      : "Download CSV"}
                  </button>
                  <button
                    onClick={() => downloadFiles("xlsx", false)}
                    disabled={
                      loadingState.format === "xlsx" &&
                      loadingState.isOnlyTable === false
                    }
                    className="bg-white text-green-500 px-4 py-2 border border-green-400 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 hover:bg-green-400 hover:text-white transition duration-300 ease-in-out"
                  >
                    {loadingState.format === "xlsx" &&
                    loadingState.isOnlyTable === false
                      ? "Downloading..."
                      : "Download XLSX"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>No files to display.</p>
        )}

        <button
          onClick={closeBulkDownloadModal}
          className="absolute top-4 right-4"
        >
          <RxCross2 size={24} color="#000" />
        </button>
      </Modal>
      {/* Preview Modal */}
      {isModalOpen && (
        <PreviewModal
          isPreviewModalOpen={isModalOpen}
          closePreviewModal={closeModal}
          previewModalContent={selectedFile}
        />
      )}
    </div>
  );
};

export default BulkDownloadModal;
