import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [credits, setCredits] = useState(0);
  const [isCompanyOwner, setIsCompanyOwner] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [tableLoader, setTableLoader] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [typeFilter, setTypeFilter] = useState("All");
  const [fileTypeFilter, setFileTypeFilter] = useState("all");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [fileStatus, setFileStatus] = useState({
    completed: 0,
    pending: 0,
    in_process: 0,
  });

  const fetchFileStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/files-status/`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("auth")).access
            }`,
            Accept: "application/json",
          },
        }
      );
      setFileStatus(response.data);
    } catch (error) {
      console.error("Error fetching file status:", error);
    }
  };

  useEffect(() => {
    const authData = localStorage.getItem("auth");
    const subscription = JSON.parse(localStorage.getItem("subscription"));
    if (authData) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    // console.log(subscription, typeof subscription);

    if (subscription === true) {
      setIsSubscribed(true);
    } else {
      setIsSubscribed(false);
    }

    const userdata = JSON.parse(localStorage.getItem("userData")) || {};
    setIsCompanyOwner(userdata?.is_company_owner || false);
    setCredits(userdata?.credits_available);

    setLoading(false);
  }, []);

  // console.log(isSubscribed);

  const login = (authData) => {
    localStorage.setItem("auth", authData);
    setIsLoggedIn(true);
    fetchData();
    userData();
  };

  const logout = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("folders");
    localStorage.removeItem("userData");
    localStorage.removeItem("subscription");
    localStorage.removeItem("isCompanyOwner");
    setIsLoggedIn(false);
    setTableData([]);
    setAllTableData([]);
  };

  const userData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;

      if (!token) {
        console.error("Access token not found");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/user-details/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        const newUserData = response.data.data;

        const existingUserData =
          JSON.parse(localStorage.getItem("userData")) || {};

        const updatedUserData = { ...existingUserData, ...newUserData };

        localStorage.setItem("userData", JSON.stringify(updatedUserData));
        // console.log(response?.data);

        localStorage.setItem(
          "isCompanyOwner",
          response?.data?.data?.is_company_owner || false
        );
        setIsCompanyOwner(response?.data?.data?.is_company_owner || false);
        setCredits(response?.data?.data?.credits_available);

        const { trial_expired_at, razorpay_subscription_end_at } = newUserData;

        const trialExpiredDate = new Date(trial_expired_at * 1000);
        const currentDate = new Date();

        let subscriptionEndDate;
        if (razorpay_subscription_end_at) {
          subscriptionEndDate = new Date(razorpay_subscription_end_at * 1000);
        }

        const isTrialExpired = trialExpiredDate < currentDate;
        const isSubscriptionExpired =
          subscriptionEndDate && subscriptionEndDate < currentDate;

        if (isTrialExpired && (!subscriptionEndDate || isSubscriptionExpired)) {
          setIsSubscribed(false);
          localStorage.setItem("subscription", "false");
          navigate("/plans");
        } else {
          // console.log("Subscription or trial is still active.");
          setIsSubscribed(true);
          localStorage.setItem("subscription", "true");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to process folder and subfolder names and IDs for localStorage
  const processFoldersForStorage = (folderList, userId) => {
    const folderListForStorage = [];

    const processFoldersRecursively = (folders) => {
      return folders
        .filter((folder) => folder.owner === userId) // Only include folders owned by the user
        .map((folder) => {
          return {
            id: folder.id,
            name: folder.name,
            subfolders: processFoldersRecursively(folder.subfolders || []), // Recursively add subfolders
          };
        });
    };

    return processFoldersRecursively(folderList);
  };

  // Function to process folders with documents and subfolders
  const processFoldersWithDocuments = (folderList, userId) => {
    const folderMap = {};

    const processFoldersRecursively = (folders) => {
      return folders
        .filter((folder) => folder.owner === userId) // Only include folders owned by the user
        .map((folder) => {
          folderMap[folder.id] = {
            ...folder,
            documents: Array.isArray(folder.files)
              ? folder.files.filter((file) => file.owner === userId)
              : [],
            subfolders: processFoldersRecursively(folder.subfolders || []), // Recursively process subfolders
          };

          return folderMap[folder.id];
        });
    };

    processFoldersRecursively(folderList);
    return folderMap;
  };

  // Main fetchData function
  const fetchData = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      const userId = JSON.parse(localStorage.getItem("auth"))?.user?.id;

      if (!token || !userId) {
        console.error("Access token or user ID not found in localStorage.");
        return;
      }
      setTableLoader(true);

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/contents/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data) {
        const { folders, files } = response.data;

        // Process folder names and subfolder structure for localStorage
        const folderListForStorage = processFoldersForStorage(folders, userId);

        // Store the folder structure (with subfolders) in localStorage
        localStorage.setItem("folders", JSON.stringify(folderListForStorage));

        // Process folders with documents and subfolders
        const folderMap = processFoldersWithDocuments(folders, userId);

        // Handle single files (files without folders)
        const singleFiles = files.filter(
          (file) => file.folder === null && file.owner === userId
        );

        // Combine processed folder data with single files
        const combinedData = [...Object.values(folderMap), ...singleFiles];

        // Update the state with processed data
        setGroupedData(folderMap);
        setTableData(combinedData);
        setAllTableData(combinedData);
        setTableLoader(false);
      } else {
        console.error("No data found in response");
        setTableLoader(false);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      setTableLoader(false);

      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please log in again.");
        logout();
        window.location.href = "/login";
      }
    }
  };

  useEffect(() => {
    const recursiveSearch = (items) => {
      return items.filter((item) => {
        // Check if it's a folder or document name match
        const nameMatch =
          item.name &&
          item.name.toLowerCase().includes(searchKeyword.toLowerCase());

        // Check if it's a file within a folder
        let documentMatch = false;
        if (item.documents) {
          documentMatch = item.documents.some((file) =>
            file.file
              .split("/")
              .pop()
              .toLowerCase()
              .includes(searchKeyword.toLowerCase())
          );
        }

        // Check if it's a single file (not in a folder)
        const fileMatch =
          item.file_type &&
          item.file
            .split("/")
            .pop()
            .toLowerCase()
            .includes(searchKeyword.toLowerCase());

        // Check within subfolders recursively
        let subfolderMatch = false;
        if (item.subfolders) {
          const matchedSubfolders = recursiveSearch(item.subfolders);
          subfolderMatch = matchedSubfolders.length > 0;
        }

        // Return true if any of the matches are found
        return nameMatch || documentMatch || fileMatch || subfolderMatch;
      });
    };

    let filteredData = [...allTableData];

    // Apply the search filter recursively
    if (searchKeyword) {
      filteredData = recursiveSearch(filteredData);
    }

    // Apply the type filter (folder or file)
    if (typeFilter !== "All") {
      filteredData = filteredData.filter((item) => {
        if (typeFilter === "folder") {
          return item.documents || item.subfolders; // Keep folders or folders with subfolders
        } else if (typeFilter === "file") {
          return !item.documents && !item.subfolders; // Keep only files (no documents or subfolders)
        }
        return true;
      });
    }

    if (statusFilter !== "All" && typeFilter !== "folder") {
      filteredData = filteredData.filter((item) => {
        if (statusFilter === "extracted") {
          return item.status === "EXTRACTED";
        } else if (statusFilter === "not-extracted") {
          return item.status === "NOT_EXTRACTED";
        } else if (statusFilter === "extracting") {
          return item.status === "EXTRACTING";
        } else if (statusFilter === "failed") {
          return item.status === "FAILED";
        }
        return true;
      });
    }
    if (fileTypeFilter !== "all" && typeFilter !== "folder") {
      filteredData = filteredData.filter((item) => {
        if (fileTypeFilter === "invoice") {
          return item.file_type === "INVOICE";
        } else if (fileTypeFilter === "receipt") {
          return item.file_type === "RECEIPT";
        } else if (fileTypeFilter === "bank_statement") {
          return item.file_type === "BANK_STATEMENT";
        }
        return true;
      });
    }

    setTableData(filteredData);
  }, [searchKeyword, statusFilter, typeFilter, allTableData, fileTypeFilter]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        fetchData,
        tableData,
        setTableData,
        groupedData,
        tableLoader,
        searchKeyword,
        setSearchKeyword,
        setStatusFilter,
        statusFilter,
        setTypeFilter,
        typeFilter,
        setFileTypeFilter,
        fileTypeFilter,
        userData,
        isSubscribed,
        loading,
        isCompanyOwner,
        setCredits,
        credits,
        fileStatus,
        setFileStatus,
        fetchFileStatus,
        setAllTableData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
