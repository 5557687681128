import React, { useState } from "react";
import Modal from "react-modal";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";

const DownloadModal = ({
  isDownloadModalOpen,
  closeDownloadModal,
  downloadModalContent,
}) => {
  const [loadingState, setLoadingState] = useState({});

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "60vw",
      minHeight: "70vh",
      maxWidth: "90vw",
      padding: "20px",
      borderRadius: "10px",
      backgroundColor: "#F6F5F5",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const downloadFile = async (fileFormat, is_only_table) => {
    try {
      setLoadingState({ format: fileFormat, isOnlyTable: is_only_table });
      const id = downloadModalContent?.id;
      const token = JSON.parse(localStorage.getItem("auth"))?.access;

      if (!id) {
        console.error("File ID is missing");
        return;
      }

      const url = `${process.env.REACT_APP_BACKEND_URL}/uploads/generate-file/?fileFormat=${fileFormat}&ids=${id}&isOnlyTable=${is_only_table}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      const filename = `${
        downloadModalContent?.file_name
          ? downloadModalContent.file_name
          : downloadModalContent?.file.split("/").pop() || "data"
      }.${fileFormat}`;

      link.href = downloadUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();

      setLoadingState({});
    } catch (error) {
      console.error("Failed to download the file", error);
      setLoadingState({});
    }
  };

  const formatName = (name) => {
    if (!name) return "";
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const isBankStatement = downloadModalContent?.file_type === "BANK_STATEMENT";

  return (
    <div className="raleway-regular">
      <Modal
        isOpen={isDownloadModalOpen}
        onRequestClose={closeDownloadModal}
        contentLabel="Download Modal"
        style={customStyles}
      >
        <h2 className="raleway-regular">Download Item</h2>
        {downloadModalContent && (
          <div className="p-4 raleway-regular">
            <div className="flex flex-wrap gap-5 justify-evenly">
              <p className="text-sm font-semibold">
                Name:{" "}
                <span className="font-normal">
                  {downloadModalContent?.file_name
                    ? downloadModalContent.file_name
                    : downloadModalContent?.file.split("/").pop()}
                </span>
              </p>
              <p className="text-sm font-semibold">
                Type:{" "}
                <span className="font-normal">
                  {formatName(downloadModalContent?.file_type)}
                </span>
              </p>
              <p className="text-sm font-semibold">
                Owner:{" "}
                <span className="font-normal">
                  {downloadModalContent?.owner_details?.first_name +
                    " " +
                    downloadModalContent?.owner_details?.last_name}
                </span>
              </p>
            </div>
            <div className="fixed bottom-12 left-0 right-0 flex justify-center space-x-4">
              {isBankStatement && (
                <div className="flex flex-col bg-slate-50 p-2 rounded-md shadow-sm">
                  <span className="font-semibold text-blue-600 mb-1">
                    Download Only Table
                  </span>
                  <div className="flex gap-5">
                    <button
                      onClick={() => downloadFile("csv", true)}
                      disabled={
                        loadingState.format === "csv" &&
                        loadingState.isOnlyTable === true
                      }
                      className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                    >
                      {loadingState.format === "csv" &&
                      loadingState.isOnlyTable === true
                        ? "Downloading..."
                        : "Download CSV"}
                    </button>
                    <button
                      onClick={() => downloadFile("xlsx", true)}
                      disabled={
                        loadingState.format === "xlsx" &&
                        loadingState.isOnlyTable === true
                      }
                      className="bg-white text-green-500 px-4 py-2 border border-green-400 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 hover:bg-green-400 hover:text-white transition duration-300 ease-in-out"
                    >
                      {loadingState.format === "xlsx" &&
                      loadingState.isOnlyTable === true
                        ? "Downloading..."
                        : "Download XLSX"}
                    </button>
                  </div>
                </div>
              )}
              <div className="flex flex-col bg-slate-50 p-2 rounded-md shadow-sm">
                {isBankStatement && (
                  <span className="font-semibold text-blue-600 mb-1">
                    Download All Data
                  </span>
                )}
                <div className="flex gap-5">
                  <button
                    onClick={() => downloadFile("csv", false)}
                    disabled={
                      loadingState.format === "csv" &&
                      loadingState.isOnlyTable === false
                    }
                    className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                  >
                    {loadingState.format === "csv" &&
                    loadingState.isOnlyTable === false
                      ? "Downloading..."
                      : "Download CSV"}
                  </button>
                  <button
                    onClick={() => downloadFile("xlsx", false)}
                    disabled={
                      loadingState.format === "xlsx" &&
                      loadingState.isOnlyTable === false
                    }
                    className="bg-white text-green-500 px-4 py-2 border border-green-400 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 hover:bg-green-400 hover:text-white transition duration-300 ease-in-out"
                  >
                    {loadingState.format === "xlsx" &&
                    loadingState.isOnlyTable === false
                      ? "Downloading..."
                      : "Download XLSX"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <button onClick={closeDownloadModal} className="absolute top-4 right-4">
          <RxCross2 size={24} color="#000" />
        </button>
      </Modal>
    </div>
  );
};

export default DownloadModal;
